/**
 *
 * @param {String} name
 * @param {String} value
 * @param {Number} expires
 * @param {String} path
 * @param {String} domain
 * @param {Boolean} secure
 */
function setCookie(name, value, expires, path, domain, secure) {
    document.cookie = name + '=' + escape(value)
        + ((expires) ? '; expires=' + new Date(expires).toUTCString() : '')
        + ((path) ? '; path=' + path + '; SameSite=Lax;' : '/; SameSite=Lax;')
        + ((domain) ? '; domain=' + domain : '')
        + ((secure) ? '; secure' : '');
}

/**
 *
 * @param {String} name
 * @return {string|boolean}
 */
function getCookie(name) {
    const pattern = '(?:; )?' + name + '=([^;]*);?';
    const regexp = new RegExp(pattern);

    if (regexp.test(document.cookie)) {
        return decodeURIComponent(RegExp['$1']);
    }

    return false;
}

/**
 *
 * @param {String} name
 */
function deleteCookie(name) {
    setCookie(name, '', 0, '/');
}
